






























































































import Component from 'vue-class-component';
import Vue from 'vue';
import RsOnboardingHeader from '@/modules/onboarding/components/main/header.vue';
import {OnboardingRouter} from '@/modules/onboarding/router/';
import RsIcon from '@/shared/components/icon/icon.vue';
import RsLinkIcon from '@/shared/components/link/link-icon.vue';
import RsOnboardingFormSignup from '@/modules/onboarding/components/forms/signup.vue';
import RsOnboardingSocialLogin from '@/modules/onboarding/components/auth/social-login.vue';
import {LOCALSTORAGE} from '@/shared/constants/LOCALSTORAGE';
import {API_SERVICE} from '@/shared/api/api.service';
import {IApiReferralCodeData} from '@/shared/models/IApiReferralCodeData';
import {Icons} from '@/shared/components/icon/icons';

@Component({
  metaInfo: {
    title: 'Sign up'
  },
  components: {RsOnboardingSocialLogin, RsOnboardingFormSignup, RsLinkIcon, RsIcon, RsOnboardingHeader}
})
export default class OnboardingReferral extends Vue {
  OnboardingRouter = OnboardingRouter;
  Icons = Icons;
  env = process.env;

  code = '';

  mounted() {
    this.code = this.$route.params.code;
    this.$track.sendEvent('ReferralPage', {referralCode: this.code})
    if (this.code) {
      localStorage.setItem(LOCALSTORAGE.REFERRAL, this.code);
      API_SERVICE.referral.getReferralCodeData(this.code)
        .then((data: IApiReferralCodeData) => {
          if(!data.valid){
            localStorage.removeItem(LOCALSTORAGE.REFERRAL);
          }
        })
        .catch(() => {
          localStorage.removeItem(LOCALSTORAGE.REFERRAL);
        })
    }
  }
}
